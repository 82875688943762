<template>
    <div>
        <header>
            <div class="container-menu menunavbg">
                <div class="logos">
                    <a href="#">
                        <img alt="Zdrava Ishrana logo" src="../assets/zi_logo.png">
                    </a>
                </div>
                <nav class="nav">
                    <ul class="nav-list">
    
    <li><a href="https://zdravaishrana.rs">Početna</a></li>
    <li class="dropdown">
            <router-link to="/" class="dropbtn">Alati</router-link>
    
            <div class="dropdown-content">
                <router-link to="/aditivi">Aditivi u Hrani</router-link>
                        <router-link to="/tk">Tablica Kalorija </router-link>
                        <router-link to="/bmi">BMI Kalkulator</router-link>
                        <router-link to="/bmr">BMR Kalkulator</router-link>
                        <router-link to="/tdee">TDEE Kalkulator</router-link>
                        <router-link to="/dpv">DPV Kalkulator</router-link>
    
            </div>
        </li>
        <li class="dropdown">
            <a href="https://zdravaishrana.rs/recepti/" class="dropbtn">Recepti</a>
            <div class="dropdown-content">
                <a href="https://zdravaishrana.rs/recepti-kategorije/predjela">Predjela</a>
                <a href="https://zdravaishrana.rs/recepti-kategorije/glavna-jela">Glavna Jela</a>
                <a href="https://zdravaishrana.rs/recepti-kategorije/riba-i-morski-plodovi/">Riba i Morski Plodovi</a>
                <a href="https://zdravaishrana.rs/recepti-kategorije/salate/">Salate</a>
                <a href="https://zdravaishrana.rs/recepti-kategorije/sosevi/">Sosovi</a>
                <a href="https://zdravaishrana.rs/recepti-kategorije/supe-i-corbe/">Supe, Čorbe i Potaži</a>
                <a href="https://zdravaishrana.rs/recepti-kategorije/salate/">Salate</a>
                <a href="https://zdravaishrana.rs/recepti-kategorije/sosovi/">Sosovi</a>
                <a href="https://zdravaishrana.rs/recepti-kategorije/testa-i-pite/">Testa i Pite</a>
                <a href="https://zdravaishrana.rs/recepti-kategorije/dezerti/">Dezerti</a>
                <a href="https://zdravaishrana.rs/recepti-kategorije/hrono-recepti/">Hrono recepti</a>
                <a href="https://zdravaishrana.rs/recepti-kategorije/keto-recepti/">Keto Recepti</a>
                <a href="https://zdravaishrana.rs/recepti-kategorije/lchf-recepti/">LCHF</a>
            </div>
        </li>
        <li class="dropdown">
            <a href="https://zdravaishrana.rs/novosti/" class="dropbtn">Novosti</a>
            <div class="dropdown-content">
                <a href="https://zdravaishrana.rs/category/ishrana/">Ishrana</a>
                <a href="https://zdravaishrana.rs/category/zdravlje/">Zdrava Ishrana</a>
                <a href="https://zdravaishrana.rs/category/mrsavljenje/">Mršavljene</a>
                <a href="https://zdravaishrana.rs/category/saveti/">Saveti</a>
                <a href="https://zdravaishrana.rs/category/lifestyle/">Lifestyle</a>
               
            </div>
        </li>
                    
                    </ul>
                </nav>
                <div class="social">
                    <ul class="list-social">
                        <li>
                            <a href="https://www.facebook.com/" target="_blank" aria-label="find us on facebook"><i class="fab fa-facebook-f"></i></a>
                        </li>
                        <li>
                            <a href="https://twitter.com/" target="_blank" aria-label="Read more on twitter"><i class="fab fa-twitter"></i></a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/" target="_blank" aria-label="follow us na instagram"><i class="fab fa-instagram"></i></a>
                        </li>
                        <li>
                            <a href="https://www.youtube.com" target="_blank" aria-label="Watch us on youtube"><i class="fab fa-youtube"></i></a>
                        </li>
                        <li>
                            <a href="https://www.tiktok.com/" target="_blank" aria-label="Fins us on tiktok"><i class="fab fa-tiktok"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
        </header>
    
        <section id="mobile-nav" class="d-block" :class="{'push-up': isAddClass}" >
            <div class="container-fluid">
                <div class="row">
                    <a class="col text-center d-flex align-items-center justify-content-center expand-mobile-nav" href="#" v-on:click="addClass">
                    MENI</a>
                </div>
                <div class="row">
                    <div class="col mobile-nav-links">
                        <li><a href="https://zdravaishrana.rs">Vrati se na Portal</a></li>
              <router-link to="/" @click="closeMenu">Alati</router-link>
              <router-link to="/aditivi" @click="closeMenu">Aditivi u Hrani</router-link>
              <router-link to="/tk" @click="closeMenu">Tablica Kalorija </router-link>
              <router-link to="/bmi" @click="closeMenu">BMI Kalkulator</router-link>
              <router-link to="/bmr" @click="closeMenu">BMR Kalkulator</router-link>
              <router-link to="/tdee" @click="closeMenu">TDEE Kalkulator</router-link>
              <router-link to="/dpv" @click="closeMenu">DPV Kalkulator</router-link>
                        
                        <!-- <router-link to="/onama">O Nama</router-link> -->
                    </div>
                </div>
            </div>
        </section>
    </div>
    </template>
    <script setup>
        import { ref, onMounted } from 'vue'
        const isAddClass = ref(false);
    
        const addClass =  () => {
            isAddClass.value = !isAddClass.value;
        }
        const closeMenu = () => {
      isAddClass.value = false;
    };
    
    </script>
    <style>
    /* Stilizacija dropdown menija */
    .dropdown {
        display: inline-block;
    }
    
    .dropdown-content {
        display: none;
        position: absolute;
        top: 30px;
        left: 0;
        background-color: #f9f9f9;
        min-width: 220px;
        z-index: 1;
        padding: 10px 20px;
        border-radius: 4px;
        border: none;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .15) !important;
        border-left: 3px solid #80c342;
    }
    
    .dropdown-content a {
        padding: 5px 10px;
        text-decoration: none;
        display: block;
    }
    
    .dropdown-content a:hover {
        color: #80c342;
    }
    
    .dropdown:hover .dropdown-content {
        display: block;
    }
    .dropbtn::after {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\f107";
        font-size: 14px;
        color: #80c342;
        margin-left: 3px;
    }
    .dropdown-content a{
        cursor: pointer;
    }
    .nav-list a {
        color: #667;
        filter: drop-shadow(1px 1px 2px rgba(0,0,0,.2));
        font-size: 16px;
    }
    @media only screen and (max-width: 600px) {
        .nav-list {
            display: none !important;
        }
    }
    @media only screen and (min-width: 601px) {
        #mobile-nav {
            display: none !important; /* Dodajte !important kako biste bili sigurni da će se primeniti */
        }
    }
    
    </style>